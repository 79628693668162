<template>
  <div @input="selectHandler"></div>
</template>

<script>

import {
  provinceList,
  cityList,
  areaList
} from '@/common/data/area';

const cityData = provinceList;
cityData.forEach((province) => {
  province.children = cityList[province.value];
  province.children.forEach((city) => {
    city.children = areaList[city.value];
  });
});

export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      selected: []
    };
  },
  // render(createElement) {
  //   return createElement('div', {
  //     on: {
  //       click: this.showPicker
  //     },
  //     class: {
  //       foo: true,
  //       bar: false
  //     },
  //     style: {
  //       color: this.selected.length ? '#333' : '#ccc',
  //       fontSize: '14px',
  //       textAlign: 'left'
  //     }
  //   }, this.selected.length ? this.selected.join(' ') : '请选择');
  // },
  mounted() {
    this.picker = this.$createCascadePicker({
      title: '',
      data: cityData,
      selectedIndex: this.value,
      onSelect: this.selectHandler
    });
  },
  methods: {
    showPicker() {
      this.picker.show();
    },
    selectHandler(selectedVal, selectedIndex, selectedTxt) {
      this.selected = selectedTxt;
      this.$emit('input', selectedVal, selectedIndex, selectedTxt);
    }
  }
};

</script>
