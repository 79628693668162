<template>
  <div class="form-custom section">
    <cube-form
      :model="model"
      class="form-wrapper"
      @submit="submitHandler"
    >
      <div class="form-content">
        <cube-form-group class="form-group">
          <cube-form-item :field="fields[0]">
            <div class="field-wrapper">
              <cube-input
                v-model="model.custCn"
                class="cube-inputs"
                placeholder="请选择"
              />
              <span
                class="dupcheck"
                @click="dupcheck"
              >查重</span>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[1]">
            <cube-input
              v-model="model.custCreditCode"
              placeholder="请输入"
              :readonly="true"
            />
          </cube-form-item>
          <cube-form-item :field="fields[2]">
            <div class="field-wrapper">
              <cube-select
                v-model="model.scale"
                class="cube-select"
                :options="fields[2].props.options"
                placeholder="请选择"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[3]">
            <div class="field-wrapper">
              <cube-select
                v-model="model.source"
                class="cube-select"
                :options="fields[3].props.options"
                placeholder="请选择"
                @change="clearClue"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <div v-if="relevanceClueList.includes(model.source)">
            <cube-form-item :field="fields[4]">
              <div
                class="field-wrapper"
                @click="toSearch('clue')"
              >
                <cube-input
                  v-model="model.clueName"
                  class="cube-inputs"
                  :readonly="true"
                  placeholder="请选择"
                />
                <i
                  v-if="!clueDisabled"
                  class="icon-joyo arrows"
                >&#xe66c;</i>
              </div>
            </cube-form-item>
          </div>
          <cube-form-item :field="fields[5]">
            <div class="field-wrapper">
              <div
                class="cube-select"
                :class="changeColor(model.siteValue)"
                @click="showAddressPicker"
              >
                {{ model.siteValue || "请选择" }}
              </div>
              <address-picker
                ref="addressPicker"
                @input="selectHandler"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[6]" />
          <!-- <cube-form-item :field="fields[7]">
            <cube-input
              v-model="model.oriProviderName"
              placeholder="请输入"
              :clearable="clearable"
            />
          </cube-form-item>
          <cube-form-item :field="fields[8]">
            <div class="field-wrapper">
              <div
                class="cube-select"
                :class="changeColor(model.contractExpire)"
                @click="showDatePicker('datePickers')"
              >
                {{ model.contractExpire || "请选择" }}
              </div>
              <date-picker
                ref="datePickers"
                :min="[2000, 1, 1]"
                :max="[2050, 1, 1]"
                @select="datesSelectHandler"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[9]" /> -->
          <cube-form-item :field="fields[10]">
            <div class="field-wrapper">
              <cube-select
                v-model="model.generalTaxpayerFlag"
                class="cube-select"
                :options="fields[10].props.options"
                placeholder="请选择"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[11]" />
        </cube-form-group>
      </div>
      <div
        class="bottom-postion btn-return-other"
      >
        <cube-button
          :light="true"
          class="btn-return"
          @click="$router.go(-1)"
        >
          返回
        </cube-button>
        <cube-button
          :disabled="!custChecked"
          type="submit"
          class="btn-other"
        >
          保存并关联
        </cube-button>
      </div>
    </cube-form>
    <search-view
      v-if="searchBool"
      :data="searchName"
      :placeholder="placeholder"
      :search-type="searchType"
      :default-func="defaultFunc"
      :func="func"
      :default-request-data="params"
      @conceal="conceal"
      @option="option"
    />
  </div>
</template>

<script>
const custTypeOptions = [{ value: 1, text: '工商企业' }, { value: 2, text: '社会组织' }, { value: 3, text: '律所' }];// 企业类型
import { mapGetters } from 'vuex';

import { custRelationClueList, custSave, custSelect, qxbinsert } from '_api/cust';
import { bindRelation } from '_api/qycust';
import addressPicker from '_c/address/index.vue';
import searchView from '_c/search';
// import datePicker from '_c/date-picker';
import config from '_lib/config';
export default {
  name: 'AddEnterprise',
  components: {
    searchView,
    addressPicker
    // datePicker
  },
  data() {
    return {
      searchBool: false,
      searchName: '',
      searchType: '',
      placeholder: '',
      custChecked: false,
      clueDisabled: false,
      defaultFunc: function() {},
      func: function() {},
      params: {},
      picker: null,
      model: {
        id: '',
        custCn: '',
        custCreditCode: '',
        scale: '',
        source: '',
        clueId: 0,
        clueName: '',
        addrProvince: '', // 省代码
        province: '',
        addrCity: '', // 市代码
        city: '',
        addrArea: '', // 区代码
        area: '',
        siteValue: '', // 显示的地址
        addrStreet: '',
        oriProviderName: '',
        contractExpire: '',
        cooperationPrice: '',
        generalTaxpayerFlag: '',
        fileIds: []
      },
      fields: [
        // 0
        {
          type: 'input',
          modelKey: 'custCn',
          label: '企业名称',
          props: {
            disabled: true
          },
          rules: {
            required: true
          }
        },
        // 1
        {
          type: 'input',
          modelKey: 'custCreditCode',
          label: '统一信用代码',
          props: { placeholder: '请输入', maxlength: 10 },
          rules: {
            type: 'text'
          }
        },
        // 2
        {
          type: 'select',
          modelKey: 'scale',
          label: '公司规模',
          props: {
            options: []
          },
          rules: {
            required: true
          }
        },
        // 3
        {
          type: 'select',
          modelKey: 'source',
          label: '客户来源',
          props: {
            options: []
          },
          rules: {
            required: true
          }
        },
        // 4
        {
          type: 'input',
          modelKey: 'clueName',
          label: '关联线索',
          props: {
            disabled: true
          },
          rules: {
            required: true
          }
        },
        // 5
        {
          type: 'select',
          modelKey: 'siteValue',
          label: '客户地址',
          rules: {
            required: true
          },
          messages: {
            required: '请选择'
          }
        },
        // 6
        {
          type: 'textarea',
          modelKey: 'addrStreet',
          label: '详细地址',
          props: { placeholder: '请输入详细地址', maxlength: 500 },
          rules: {
            required: true,
            type: 'text'
          }
        },
        // 7
        {
          type: 'input',
          modelKey: 'oriProviderName',
          label: '原服务商',
          props: { placeholder: '请输入' }
        },
        // 8
        {
          modelKey: 'contractExpire',
          label: '合同到期时间'
        },
        // 9
        {
          type: 'input',
          modelKey: 'cooperationPrice',
          label: '合同价格(元)',
          props: { placeholder: '请输入' },
          rules: {
            type: 'text'
          }
        },
        // 10
        {
          type: 'select',
          modelKey: 'generalTaxpayerFlag',
          label: '是否一般纳税人',
          props: {
            options: [{ text: '是', value: 1 }, { text: '否', value: 0 }]
          }
        },
        // 11
        {
          type: 'upload',
          modelKey: 'fileIds',
          label: '营业执照',
          events: {
            'file-removed': (...args) => { args; },
            'file-click': (...args) => {
              const imgs = args[0].response ? [args[0].response.data.record.fileUrl] : [args[0].fileUrl];
              this.$createImagePreview({
                imgs: imgs
              }).show();
            },
            'input': (...args) => { args; }
          },
          props: {
            max: 1,
            action: {
              target: `${config.BASE_URL}/client/tencent/getFileAndFileIdFeign`,
              headers: { token: this.$store.state.token },
              data: {
                type: 99,
                time: 0
              }
            }
          },
          rules: {},
          messages: {
            uploaded: '上传失败'
          }
        }
      ],
      clearable: {
        visible: true,
        blurHidden: true
      },
      relevanceClueList: [3, 4, 10, 13]
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'dictList', 'externalUserid'])
  },
  created() {
    // 获取字典
    this.getOptions();
  },
  methods: {
    changeColor(value) {
      if (value !== '' && value !== null) {
        return 'color-333';
      }
      return 'color-ccc';
    },
    showDatePicker(refName) {
      if (refName === 'datePicker' && this.trackDisabled) {
        return;
      }
      this.$refs[refName].show();
    },
    datesSelectHandler(selectedVal) {
      this.model.contractExpire = selectedVal;
    },
    selectHandler(selectedVal, selectedIndex, selectedTxt) {
      this.model.addrProvince = selectedVal[0];
      this.model.addrCity = selectedVal[1];
      this.model.addrArea = selectedVal[2];
      this.model.province = selectedTxt[0];
      this.model.city = selectedTxt[1];
      this.model.area = selectedTxt[2];
      this.model.siteValue = `${selectedTxt[0]}  ${selectedTxt[1]}  ${selectedTxt[2]}`;
    },
    // 获取字典
    getOptions() {
      this.fields[2].props.options = this.dictList.scale;
      this.fields[3].props.options = this.dictList.clueSource;
    },
    toSearch(type) {
      switch (type) {
        case 'clue':
          this.placeholder = '请输入线索名称或联系人手机号';
          this.defaultFunc = custRelationClueList;
          this.func = custRelationClueList;
          this.params = { clueSource: this.model.source, pageNum: 1, pageSize: 100 };
          break;
      }
      this.searchType = type;
      this.searchBool = true;
    },
    conceal(bool) {
      this.searchBool = bool;
    },
    option(data) {
      switch (this.searchType) {
        case 'clue':
          this.model.clueName = data.title;
          this.model.clueId = data.id;
          break;
      }
      this.model[this.searchType] = data.title;
      this.searchBool = false;
    },
    showPicker() {
      if (!this.picker) {
        this.picker = this.$createPicker({
          title: '企业类型',
          data: [custTypeOptions],
          onSelect: this.selectHandle,
          onCancel: this.cancelHandle
        });
      }
      this.picker.show();
    },
    showAddressPicker() {
      this.$refs.addressPicker.showPicker();
    },
    selectHandle(selectedVal) {
      const params = {
        comCn: this.model.custCn,
        clueId: 0,
        enterpriseWay: selectedVal[0],
        orgId: this.userInfo.postRoleResList[0].orgId,
        rootOrgId: 1
      };
      qxbinsert(params).then(res => {
        if (res.flag || res.errorCode === 'ECRM001') {
          if (res.data !== null) {
            this.model.id = res.data.id;
            this.model.custCreditCode = res.data.custCreditCode;
            this.custChecked = true;
          } else {
            this.$showToast('工商信息为空');
            this.custChecked = false;
          }
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    cancelHandle() {},
    // 清除关联线索
    clearClue() {
      this.model.clueId = 0;
      this.model.clueName = '';
    },
    // 客户查重
    dupcheck() {
      if (this.model.custCn === '') {
        this.$showToast('请填写客户名称');
        return;
      }
      custSelect(this.model.custCn).then(res => {
        if (res.flag) {
          if (res.data.custInfoCnDtoList === null || (res.data.custInfoCnDtoList && res.data.custInfoCnDtoList.length === 0)) {
            this.$showToast('重名校验通过');
            this.showPicker();
          } else {
            this.$showToast('系统已存在该客户，请勿重复创建');
            this.custChecked = false;
          }
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    submitHandler(e) {
      e.returnValue = false;
      const params = JSON.parse(JSON.stringify(this.model));
      params.licenseAfileId = params.fileIds.map(item => item.response ? item.response.data.record.fileId : item.fileId).join(',');
      params.licenseBfileId = params.fileIds.map(item => item.response ? item.response.data.record.fileId : item.fileId).join(',');
      params.orgId = this.userInfo.postRoleResList[0].orgId;
      custSave(params).then(res => {
        if (res.flag) {
          this.associatedCompanies();
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    // 关联企业
    associatedCompanies() {
      bindRelation(this.externalUserid, this.model.id, this.userInfo.userInfoRes.weChatUserId).then(res => {
        if (res.flag) {
          this.$showToast('保存并关联成功');
          this.$router.push(
            {
              name: 'QycustDetail'
            }
          );
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped src="@/assets/css/components/addstyle.scss"></style>
